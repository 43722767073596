<template>
  <el-main>
    <div class="title_tab">{{ name }}</div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <!-- 审核状态 -->
            <el-select v-model="czVal" placeholder="审核状态" clearable
              style="width: 8%; margin-right: 20px; marginleft: 10px">
              <el-option v-for="item in selectCz" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <!-- 任务状态 -->
            <el-select v-model="rwVal" placeholder="任务状态" clearable
              style="width: 8%; margin-right: 20px; marginleft: 10px">
              <el-option v-for="item in selectRw" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <!-- 口令 -->
            <el-input style="width: 12%; margin-right: 20px; marginleft: 10px" v-model="queryAdv_sn"
              placeholder="请输入查询条件"></el-input>
            <!-- 用户名 -->
            <!-- <el-input
              style="width: 12%; margin-right: 20px; marginleft: 10px"
              v-model="queryUserName"
              placeholder="请输入广告口令-识别号"
            ></el-input> -->
            <el-button style="margin-bottom: 20px; margin-left: 20px" icon="el-icon-search" @click="getDataList()">查
              询</el-button>
            <el-button v-no-more-click icon="el-icon-refresh" @click="getDataList('rf')">刷 新
            </el-button>
            <el-button style="margin-bottom: 20px" icon="el-icon-download" @click="batchExports()">下载
            </el-button>
            <br />
            <br />
            <!-- 公告列表 -->
            <el-table :data="DataList" @selection-change="handleSelectionChange" size="small" style="width: 100%"
              height="660" max-height="800">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="ID" width="50" fixed="left" prop="id">
              </el-table-column>
              <el-table-column label="审核状态" fixed="left" align="center" width="180" prop="status_audit">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status_audit == 1" type="success">
                    审核通过
                  </el-tag>
                  <el-tag v-if="scope.row.status_audit == 0" type="warning">
                    审核中
                  </el-tag>
                  <el-tag v-if="scope.row.status_audit == -1" type="danger">
                    审核驳回
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column label="任务状态" align="center" fixed="left" width="180" prop="status_run">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status_run == 0" type="warning">
                    未开始
                  </el-tag>
                  <el-tag v-if="scope.row.status_run == 1" type="success">
                    已开始
                  </el-tag>
                  <el-tag v-if="scope.row.status_run == 2" type="danger">
                    暂停
                  </el-tag>
                  <el-tag v-if="scope.row.status_run == -1" type="danger">
                    结束
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column label="用户名" align="center" width="180" prop="users">
              </el-table-column>
              <el-table-column label="口令-识别号" align="center" width="180" prop="adv_sn">
              </el-table-column>
              <el-table-column label="投放模式" align="center" width="180" prop="push_type">
                <template slot-scope="scope">
                  <div v-if="scope.row.push_type == 1">流量</div>
                  <div v-if="scope.row.push_type == 2">销量</div>
                </template>
              </el-table-column>
              <el-table-column label="展示类型" align="center" width="180" prop="show_type">
                <template slot-scope="scope">
                  <div v-if="scope.row.show_type == 1">网站</div>
                  <div v-if="scope.row.show_type == 2">信息应用</div>
                </template>
              </el-table-column>
              <el-table-column label="目标策略" align="center" width="180" prop="target">
                <template slot-scope="scope">
                  <div v-if="scope.row.target == 1">转化量最大化</div>
                  <div v-if="scope.row.target == 2">转化价值最大化</div>
                </template>
              </el-table-column>
              <el-table-column label="开始日期" align="center" width="180" prop="begin_date">
              </el-table-column>
              <el-table-column label="开始时间" align="center" width="180" prop="begin_time">
              </el-table-column>
              <el-table-column label="结束日期" align="center" width="180" prop="end_date">
              </el-table-column>
              <el-table-column label="结束时间" align="center" width="180" prop="end_time">
              </el-table-column>
              <el-table-column label="单日预算" align="center" width="180" prop="day_price">
              </el-table-column>
              <el-table-column label="持续天数" align="center" width="180" prop="day">
              </el-table-column>
              <el-table-column label="总预算" align="center" width="180" prop="total_price">
              </el-table-column>
              <el-table-column label="总费用" align="center" width="180" prop="total_amount">
              </el-table-column>
              <el-table-column label="投放国家" align="center" width="140" prop="push_country">
              </el-table-column>
              <el-table-column label="投放城市" align="center" width="140" prop="push_address">
              </el-table-column>
              <el-table-column label="目标群体年龄" align="center" width="180" prop="person_age">
              </el-table-column>
              <el-table-column label="目标群体性别" align="center" width="180" prop="person_sex">
              </el-table-column>
              <el-table-column label="语言" align="center" width="180" prop="language">
              </el-table-column>
              <el-table-column label="广告标题" align="center" width="180" prop="adv_title" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="广告描述" align="center" width="220" prop="adv_description"
                :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column label="广告图片" align="center" width="180" prop="adv_image">
                <template slot-scope="scope">
                  <el-image style="width: 44px; height: 44px" :src="scope.row.coverUrl[0]"
                    :preview-src-list="scope.row.coverUrl">
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column label="广告网站URL" align="center" width="180" prop="adv_web" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <a :href="scope.row.adv_web" target="_blank" rel="noopener noreferrer">{{ scope.row.adv_web }}</a>
                </template>
              </el-table-column>
              <el-table-column label="WhatsApp" align="center" width="180" prop="whatsapp">
              </el-table-column>
              <el-table-column label="创建时间" align="center" width="180" prop="createtime">
              </el-table-column>
              <el-table-column label="更新时间" align="center" width="180" prop="updatetime">
              </el-table-column>
              <el-table-column label="操作 " align="center" fixed="right" width="180">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="editStaus(scope.row)">编辑状态</el-button>
                  <el-button :disabled="scope.row.status_audit == 1" type="text"
                    @click="editNotice(scope.row)">审核</el-button>
                  <el-popconfirm title="确定删除吗？" @confirm="removeStatus(scope.row)">
                    <el-button slot="reference" :disabled="scope.row.status_run != -1" type="text"
                      style="margin-left: 12px">删除</el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="noticeTotal">
            </el-pagination>
          </div>
          <!-- 审核认证 -->
          <el-dialog custom-class="ls" title="审核" :visible.sync="newCardsdialogVisible" width="30%">
            <el-form label-position="top" label-width="80px" :model="form">
              <!-- 标题 -->
              <el-form-item label="选择审核状态">
                <el-switch v-model="form.status" active-color="#13ce66" inactive-color="#ff4949" active-text="通过"
                  inactive-text="驳回">
                </el-switch>
              </el-form-item>
              <!-- 内容 -->
              <el-form-item label="拒绝原因">
                <el-input type="textarea" v-model="form.reason" :autosize="{ minRows: 6, maxRows: 50 }"></el-input>
              </el-form-item>
            </el-form>
            <div class="form_foot">
              <el-button @click="addNotice()" v-no-more-click>确 定</el-button>
              <el-button @click="newCardsdialogVisible = false">取 消</el-button>
            </div>
          </el-dialog>
          <!-- 编辑状态 -->
          <el-dialog custom-class="ls" title="编辑" :visible.sync="editordsdialogVisible" width="30%">
            <el-form label-position="top" label-width="80px" :model="editorForm">
              <!-- 标题 -->
              <el-form-item label="选择运行状态">
                <el-radio v-model="editorForm.status_run" :label="1">开始</el-radio>
                <el-radio v-model="editorForm.status_run" :label="2">暂停</el-radio>
                <el-radio v-model="editorForm.status_run" :label="-1">结束</el-radio>
              </el-form-item>
              <!-- 内容 -->
              <el-form-item label="广告凭证截图 ">
                <!-- <el-input v-model="editorForm.result_image" clearable>
                </el-input> -->
                <el-upload action="#" list-type="picture-card" accept="image/jpeg, image/png, image/jpg"
                  :http-request="handleUpload" :before-upload="uploadFile" :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove" :limit="3" :file-list="fileList">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :append-to-body="true" top="5vh" :visible.sync="dialogImageVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
            </el-form>
            <div class="form_foot">
              <el-button @click="addStatus()" v-no-more-click>确 定</el-button>
              <el-button @click="editordsdialogVisible = false">取 消</el-button>
            </div>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { put, signatureUrl, getFileNameUUID } from "@/utils/alioss.js";
import { dataConversionUtil } from "../../plugins/excel.js";
export default {
  data() {
    return {
      name: "广告审核",
      noticeTotal: 0,
      form: {
        status: false,
        reason: "", //拒绝原因
      },
      selectId: "",
      queryAdv_sn: "", // 查询参数 识别号
      queryUserName: "",
      DataList: [],
      czVal: "", // 查询参数
      selectCz: [
        {
          value: 0,
          label: "审核中",
        },
        {
          value: 1,
          label: "审核通过",
        },
        {
          value: -1,
          label: "审核驳回",
        },
      ],
      rwVal: "", // 查询参数
      selectRw: [
        {
          value: 0,
          label: "未开始",
        },
        {
          value: 1,
          label: "已开始",
        },
        {
          value: 2,
          label: "暂停",
        },
        {
          value: -1,
          label: "结束",
        },
      ],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页数据显示 条数
      newCardsdialogVisible: false, // 审核窗口
      editordsdialogVisible: false, // 编辑窗口
      editorForm: {
        status_run: 1,
        result_image: "",
      },
      fileList: [],
      dialogImageUrl: "",
      dialogImageVisible: false,
      multipleSelection: [],
    };
  },
  methods: {
    batchExports() {
      const tableHeader = [
        [
          "审核状态",
          "任务状态",
          "用户名",
          "口令-识别号",
          "投放模式",
          "展示类型",
          "目标策略",
          "开始日期",
          "开始时间",
          "结束日期",
          "结束时间",
          "单日预算",
          "持续天数",
          "总预算",
          "总费用",
          "投放国家",
          "投放城市",
          "目标群体年龄",
          "目标群体性别",
          "语言",
          "广告标题",
          "广告描述",
          "广告图片",
          "广告网站URL",
          "WhatsApp",
          "创建时间",
        ],
      ];
      const dataList = [];
      // 将导出数据存放在this.mumultipleSelection中 ,遍历将要导出的数据
      this.multipleSelection.forEach((item, index) => {
        // 审核状态
        let sta = "";
        switch (item.status_audit) {
          case 0:
            sta = "审核中";
            break;
          case 1:
            sta = "审核通过";
            break;
          case -1:
            sta = "审核驳回";
            break;
        }
        // 任务状态
        let ty = "";
        switch (item.status_run) {
          case 0:
            ty = "未开始";
            break;
          case 1:
            ty = "已开始";
            break;
          case 2:
            ty = "暂停";
            break;
          case -1:
            ty = "结束";
            break;
        }
        console.log("item.adv_image: ", JSON.parse(item.adv_image));
        let arrImg = JSON.parse(item.adv_image);
        // 图片
        const imgList = arrImg.map((item) => item.url);
        // console.log("imgList: ", imgList.join(","));
        const imgs = imgList.join("\n");
        dataList.push([
          sta,
          ty,
          item.users,
          item.adv_sn,
          item.push_type == 1 ? "流量" : "销量",
          item.show_type == 1 ? "网站" : "信息应用",
          item.target == 1 ? "转化量最大化" : "转化价值最大化",
          item.begin_date,
          item.begin_time,
          item.end_date,
          item.end_time,
          item.day_price,
          item.day,
          item.total_price,
          item.total_amount,
          item.push_country,
          item.push_address,
          item.person_age,
          item.person_sex,
          item.language,
          item.adv_title,
          item.adv_description,
          imgs,
          item.adv_web,
          item.whatsapp,
          item.createtime,
        ]);
      });
      dataConversionUtil.dataToExcel("导出列表名", tableHeader, dataList);
      this.$message.success("导出成功!");
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getFileName(name) {
      return name.substring(name.lastIndexOf(".")); //.txt
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = fileList;
    },
    handlePictureCardPreview(file) {
      console.log("file: ", file);
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },
    uploadFile(file) {
      console.log("this.fileList.length: ", this.fileList.length);
      if (this.fileList.length == 3) {
        this.$message({
          type: "warning",
          message: "最多只能上传3张图片",
        });
        return;
      }
      return file;
    },
    handleUpload(option) {
      var obj = option.file.name;
      var index = obj.lastIndexOf(".");
      obj = obj.substring(index, obj.length);
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = getFileNameUUID() + this.getFileName(option.file.name);
      put("/ADIamge/" + option.file.name, option.file).then((res) => {
        // 上传成功之后，转换真实的地址
        signatureUrl("/ADIamge/" + option.file.name).then((resSign) => {
          this.fileList.push({
            name: objName,
            url: resSign.slice(0, resSign.indexOf("?")),
            status: "success",
          });
        });
      });
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    // 审核
    editNotice(item) {
      console.log("item: ", item);
      this.form = {
        status: item.status_audit == 1 ? true : false,
        reason: item.reason || "", //拒绝原因
      };
      this.selectId = item.id;
      this.newCardsdialogVisible = true;
    },
    editStaus(item) {
      // console.log("item: ", item);
      this.editorForm = {
        status_run: item.status_run || 0,
        result_image: "", // 结果图片
      };
      item.result_image
        ? (this.fileList = JSON.parse(item.result_image))
        : (this.fileList = []);
      this.selectId = item.id;
      this.editordsdialogVisible = true;
    },
    // 审核确认弹窗
    addNotice() {
      console.log(
        "%c [ this.form ]-123",
        "font-size:13px; background:pink; color:#bf2c9f;",
        this.form
      );
      let params = {
        id: this.selectId,
        status_audit: this.form.status ? 1 : -1,
        reason: this.form.reason,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/auditAdvert", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then((res) => {
        this.getDataList();
        this.newCardsdialogVisible = false;
      });
    },
    // 删除
    removeStatus(row) {
      // if (this.fileList.length == 0) {
      //   this.$message.warning("请上传图片");
      // }
      let params = {
        id: row.id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/deleteAdvert", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then((res) => {
        this.getDataList();
        this.editordsdialogVisible = false;
      });
    },
    // 编辑状态
    addStatus() {
      // if (this.fileList.length == 0) {
      //   this.$message.warning("请上传图片");
      // }
      let params = {
        id: this.selectId,
        status_run: this.editorForm.status_run,
        result_image: JSON.stringify(this.fileList),
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/updateAdvert", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then((res) => {
        this.getDataList();
        this.editordsdialogVisible = false;
      });
    },
    // 获取数据
    getDataList(flag) {
      if (flag && flag == "rf") {
        this.currentPage = 1;
        this.pageSize = 10;
        this.czVal = "";
        this.rwVal = "";
        this.queryAdv_sn = "";
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        status_audit: String(this.czVal) || 99,
        status_run: String(this.rwVal) || 99,
        adv_sn: String(this.queryAdv_sn),
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/adminAdvertList", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.DataList = res.data.data.data;
          this.noticeTotal = res.data.data.total;
          for (let i = 0; i < this.DataList.length; i++) {
            this.DataList[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
            this.DataList[i].updatetime = this.getTime(
              res.data.data.data[i].updatetime
            );
            const file = JSON.parse(res.data.data.data[i].adv_image);
            this.DataList[i].coverUrl = file.map((item) => item.url);
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts / 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      // 可依据须要在这里定义时间格式
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
  },
  created() {
    this.getDataList();
  },
  mounted() { },
};
</script>

<style scoped>
.form_foot {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
</style>
