<template>
    <el-main>
        <div class="title_tab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('balanceDetails.home') }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="setting-box">
            <!-- 菜单 -->
            <div class="setting-box-menu">
                <div :class="[activeName == '1' ? 'setting-box-menu-item active' : 'setting-box-menu-item']"
                    @click="tabschange('1')">
                    <i class="el-icon-user"></i>
                    {{ $t('userInfos.menu1') }}
                </div>
                <div :class="[activeName == '2' ? 'setting-box-menu-item active' : 'setting-box-menu-item']"
                    @click="tabschange('2')">
                    <i class="el-icon-setting"></i>
                    {{ $t('userInfos.menu2') }}
                </div>
                <div :class="[activeName == '3' ? 'setting-box-menu-item active' : 'setting-box-menu-item']"
                    @click="tabschange('3')">
                    <i class="el-icon-document"></i>
                    {{ $t('userInfos.menu3') }}
                </div>
                <div :class="[activeName == '4' ? 'setting-box-menu-item active' : 'setting-box-menu-item']"
                    @click="tabschange('4')">
                    <i class="el-icon-connection"></i>
                    {{ $t('userInfos.menu4') }}
                </div>
            </div>
            <!-- 详情 -->
            <div class="setting-box-detail">
                <!-- 个人信息 -->
                <div class="setting-box-detail-user" v-if="activeName == '1'">
                    <!-- 标题 -->
                    <div class="setting-box-detail-user-title">{{ $t('userInfos.title1') }}</div>
                    <!-- 信息 -->
                    <div>
                        <el-descriptions>
                            <el-descriptions-item :label="$t('userInfos.username')">
                                <el-tag size="small">{{ userInfo.userName }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item :label="$t('userInfos.loginAddress')">
                                <el-tag size="small">{{ userInfo.reg_address }}</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item :label="$t('userInfos.email')">
                                <el-tag size="small">{{ userInfo.email }}</el-tag>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <el-divider></el-divider>
                    <!-- 标题 -->
                    <div class="setting-box-detail-user-title">{{ $t('userInfos.title2') }}</div>
                    <span style="font-size: 14px;margin-right: 8px;">{{ $t('userInfos.switchLabel') }}</span>
                    <el-switch v-model="userInfo.login_type" :active-value="1" @change="setLoginType"
                        :inactive-value="2" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </div>
                <!-- 修改密码 -->
                <div class="setting-box-detail-password" v-if="activeName == '2'">
                    <div class="setting-box-detail-password-title">{{ $t('userInfos.title3') }}</div>
                    <div class="setting-box-detail-password-form">
                        <input v-model="form_changePWD.oldPWD" type="text" style="margin-bottom: 60px;"
                            :placeholder="$t('userInfos.inputPlaceholder1')">
                        <input v-model="form_changePWD.newPWD" type="text"
                            :placeholder="$t('userInfos.inputPlaceholder2')">
                        <div class="setting-box-detail-password-rule">{{ $t('userInfos.tips2') }}</div>
                    </div>
                    <div class="setting-box-detail-password-btn" @click="changePass">
                        {{ $t('userInfos.sumitbtn') }}
                    </div>
                </div>
                <!-- 实名信息 -->
                <div class="setting-box-detail-realname" v-if="activeName == '3'">
                    <!-- 标题 -->
                    <div class="setting-box-detail-realname-title">
                        {{ $t('userInfos.title5') }}
                    </div>
                    <!-- 表单 -->
                    <div >
                        <div class="setting-box-detail-realname-form">
                            <div class="label_text1">{{ label1 }}</div>
                            <input v-model="userInfo.realname" type="text" disabled>
                        </div>
                        <div class="setting-box-detail-realname-form">
                            <div class="label_text1">{{ label2 }}</div>
                            <input v-model="userInfo.realcard" type="text" disabled>
                        </div>
                    </div>
                </div>
                <!-- 绑定谷歌 -->
                <div class="setting-box-detail-google" v-show="activeName == '4'">
                    <!-- 标题 -->
                    <div class="setting-box-detail-google-title">{{ $t('userInfos.title4') }}</div>
                    <!-- 绑定二维码 -->
                    <div class="setting-box-detail-google-qrcode">
                        <img src="../../images/svg/authQRcode.svg" alt="">
                        <div class="setting-box-detail-google-qrcode-text">
                            <div class="setting-box-detail-google-qrcode-text-content">
                                {{ $t('userInfos.tips1') }}
                            </div>
                            <img src="../../images/right.png" alt="">
                        </div>
                        <img :src="ggUrl" alt="">
                    </div>
                </div>
            </div>
        </div>
    </el-main>
</template>

<script>
export default {
    data() {
        return {
            name: this.$t('userInfos.menutitle'),
            activeName: '1',
            userInfo: {
                userName: ''
            },
            label1:'',
            label2: '',
            form_changePWD: {
                newPWD: "",
                oldPWD: "",
            },
            ggUrl: ''
        }
    },
    created() {
        this.getuserinfos();
        this.getG()
    },
    methods: {
        // 修改密码
        changePass() {
            // 正则表达式验证新密码只能包含大小写字母和数字
            const passwordRegex = /^[A-Za-z0-9]+$/;

            if (!passwordRegex.test(this.form_changePWD.newPWD)) {
                this.$message({
                    message: this.$t('userInfos.tips2'),
                    type: "error",
                });
                return;
            }

            let params = {
                pass: this.form_changePWD.oldPWD,
                newpass: this.form_changePWD.newPWD,
            };
            params = this.$lmjm(params);
            const result = this.$http.post("/api/v1/editpass", params, {
                headers: {
                    token: window.localStorage.getItem("token"),
                },
            });
            result.then(
                (res) => {
                    this.$message({
                        message: "修改成功，请重新登录!",
                        type: "success",
                    });
                    this.form_changePWD.newPWD = "";
                    this.form_changePWD.oldPWD = "";
                    window.localStorage.clear();
                    this.$router.push("/login");
                },
                (error) => {
                    this.$message({
                        message: "修改失败!",
                        type: "error",
                    });
                    this.form_changePWD.newPWD = "";
                    this.form_changePWD.oldPWD = "";
                }
            );
        },
        // 设置邮箱登录方式
        setLoginType(e) {
            let params = {
                login_type: e,
            };
            params = this.$lmjm(params);
            const result = this.$http.post("/api/v1/updatelogintype", params, {
                headers: {
                    token: window.localStorage.getItem("token"),
                },
            });
            result.then((res) => {
                console.log("res: ", res);
                if (res.data.code == 200) {
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
                this.getuserinfos();
            });

        },
        tabschange(key) {   
            if(key == '3'){
                if(this.userInfo.isreal == 2){
                    this.label1 = this.$t('userInfos.lable1')
                    this.label2 = this.$t('userInfos.lable2')
                }else if(this.userInfo.isreal == 4){
                    this.label1 = this.$t('userInfos.lable3')
                    this.label2 = this.$t('userInfos.lable4')
                }
            }
            if (key == '3') {
                this.form_changePWD = {
                    newPWD: "",
                    oldPWD: "",
                }
            }
            this.activeName = key;
        },
        getuserinfos() {
            let params = {};
            params = this.$lmjm(params);
            const result = this.$http.post("/api/v1/getuserinfos", params, {
                headers: {
                    token: window.localStorage.getItem("token"),
                },
            });
            result.then(
                (res) => {
                    this.userInfo.userName = res.data.data.username;
                    this.userInfo.cardnum = res.data.data.cardnum;
                    this.userInfo.cny = res.data.data.cny;
                    this.userInfo.nums = res.data.data.nums;
                    this.userInfo.pernums = res.data.data.pernums;
                    this.userInfo.reg_address = res.data.data.reg_address;
                    this.userInfo.reg_time = this.getTime(res.data.data.reg_time);
                    this.userInfo.relv = res.data.data.relv;
                    this.userInfo.usd = res.data.data.usd;
                    this.userInfo.remoney = res.data.data.remoney;
                    this.userInfo.isreal = res.data.data.isreal;
                    this.userInfo.realname = res.data.data.realname;
                    this.userInfo.realcard = res.data.data.realcard;
                    this.userInfo.usd_disabled_mon = res.data.data.usd_disabled_mon;
                    this.userInfo.cny_disabled_mon = res.data.data.cny_disabled_mon;
                    this.userInfo.email = res.data.data.email;
                    this.userInfo.login_type = res.data.data.login_type;
                    if(this.userInfo.isreal == 2){
                        this.label1 = this.$t('userInfos.lable1')
                        this.label2 = this.$t('userInfos.lable2')
                    }else if(this.userInfo.isreal == 4){
                        this.label1 = this.$t('userInfos.lable3')
                        this.label2 = this.$t('userInfos.lable4')
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        getG() {
            let params = {};
            params = this.$lmjm(params);
            const result = this.$http.post("/api/v1/setchromeAthcode", params, {
                headers: {
                    token: window.localStorage.getItem("token"),
                },
            });
            result.then(
                (res) => {
                    this.ggUrl = res.data.data.url;
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        // 格式化时间
        getTime(/** timestamp=0 **/) {
            const ts = arguments[0] || 0;
            let t, y, m, d, h, i, s;
            t = ts ? new Date(ts * 1000) : new Date();
            y = t.getFullYear();
            m = t.getMonth() + 1;
            d = t.getDate();
            h = t.getHours();
            i = t.getMinutes();
            s = t.getSeconds();
            // 可依据须要在这里定义时间格式
            return (
                y +
                "-" +
                (m < 10 ? "0" + m : m) +
                "-" +
                (d < 10 ? "0" + d : d) +
                " " +
                (h < 10 ? "0" + h : h) +
                ":" +
                (i < 10 ? "0" + i : i) +
                ":" +
                (s < 10 ? "0" + s : s)
            );
        },
    }
}
</script>

<style lang="less" scoped>
* {
    box-sizing: border-box;
}

.setting-box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;

    .setting-box-menu {
        padding-top: 24px;
        width: 20%;
        height: 800px;
        border-radius: 12px;
        background-color: var(--userMenuBck);

        .setting-box-menu-item {
            cursor: pointer;
            width: 95%;
            height: 44px;
            line-height: 44px;
            padding-left: 15%;
            border-radius: 0 22px 22px 0;
            // border: 1px red solid;
            font-size: 14px;
            margin-bottom: 8px;
            transition: all .1s linear;
        }

        .active {
            background-color: var(--userMenuBckActive);
            color: #fff;
        }
    }

    .setting-box-detail {
        width: 79%;
        height: 800px;
        height: 800px;
        border-radius: 12px;
        background-color: var(--bgColor);

        .setting-box-detail-user {
            // border: 1px red solid;
            padding: 20px;

            .setting-box-detail-user-title {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 30px;
                margin-top: 20px;
                font-family: 'pingfang';
            }
        }

        .setting-box-detail-password {
            padding: 20px;

            .setting-box-detail-password-title {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 30px;
                margin-top: 20px;
                font-family: 'pingfang';
            }

            .setting-box-detail-password-form {
                display: flex;
                flex-direction: column;

                input {
                    border-radius: 12px;
                    border: 1px solid #ccc;
                    width: 500px;
                    height: 44px;
                    font-size: 16px;
                    padding-left: 10px;
                    outline: none;
                    color: var(--mainText); 
                    background: var(--userInfoInput);
                    
                }

                input:focus {
                    border: 2px solid #31313a;
                    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
                }

                .setting-box-detail-password-rule {
                    margin-top: 12px;
                    font-size: 12px;
                    color: #777;
                }
            }

            .setting-box-detail-password-btn {
                cursor: pointer;
                margin-top: 90px;
                width: 500px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                background-color: #31313a;
                color: #fff;
                font-weight: bold;
                letter-spacing: 2px;
                border-radius: 22px;
                transition: all .1s linear;
            }

            .setting-box-detail-password-btn:hover {
                transform: translateY(-5px);
            }

            .setting-box-detail-password-btn:active {
                transform: translateY(0);
            }
        }

        .setting-box-detail-google {
            padding: 20px;

            .setting-box-detail-google-title {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 30px;
                margin-top: 20px;
                font-family: 'pingfang';
            }

            .setting-box-detail-google-qrcode {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;
                height: 100%;
                margin-top: 65px;

                img {
                    width: 400px;
                    height: 400px;
                    border-radius: 12px;
                }

                .setting-box-detail-google-qrcode-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 44px;
                        height: 44px;
                    }

                    .setting-box-detail-google-qrcode-text-content {
                        font-size: 12px;
                        color: var(--mainText); 
                        margin: 24px 0;
                    }
                }
            }
        }

        .setting-box-detail-realname{
            padding: 20px;

            .setting-box-detail-realname-title {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 30px;
                margin-top: 20px;
                font-family: 'pingfang';
            }
            .setting-box-detail-realname-form{
                display: flex;
                align-items: center;
                margin-bottom: 25px;
                .label_text1{
                    width: 120px;
                    text-align: right;
                    font-size: 14px;
                    margin-right: 12px;
                    font-family: 'pingfang';
                    font-weight: 500;
                }
                input {
                    border-radius: 12px;
                    border: 1px solid #ccc;
                    width: 500px;
                    height: 44px;
                    font-size: 16px;
                    padding-left: 10px;
                    outline: none;
                    color: var(--mainText); 
                    background: var(--userInfoInput);
                }
            }
        }
    }
}
</style>