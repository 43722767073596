import twLocale from "element-ui/lib/locale/lang/zh-TW";
const hk = {
  menu: {
    home: "首頁",
    Recharge: {
      title: "充值",
      subMenu: {
        onlineRecharge: "在線充值",
        myWallet: "我的錢包",
        rechargrLogs: "賬戶明細",
        BalanceStatement: "余額流水",
        withdraw: "余额提现",
      },
    },
    card: {
      title: "卡片",
      subMenu: {
        applyCard: "申請卡片",
        myCard: "我的卡片",
        saleLogs: "交易記錄",
        Recordofdishonour: "拒付記錄",
      },
    },
    extend: {
      title: "推廣",
      subMenu: {
        AlliancePromotion: "聯盟推廣",
        SplitDiary: "分成日記",
      },
    },
    advertisement: {
      title: "廣告",
      subMenu: {
        CorporateAdvertising: "企業廣告",
        EnterpriseCertification: "企業認證",
        AdvertisingAlliance: "廣告聯盟",
      },
    },
    shopping: {
      title: "商城",
      tips: "正在開發中......",
    },
    withdraw: {
      title: "余額提現",
    },
    Toggle: {
      title: "切換",
    },
    setting: {
      title: "設置",
      subMenu: {
        IndividualCenter: "用戶",
        GoogleBinding: "谷歌綁定",
        ChangePassword: "修改密碼",
        logout: "退出登錄",
        Auth:"實名認證"
      },
    },
    scanTheCodeOnWeChat: "微信掃一掃",
  },
  userInfos: {
    menutitle:"個人中心",
    menu1:"個人信息",
    menu2:"修改密碼",
    menu3:"實名信息",
    menu4:"綁定谷歌",
    title1:"詳細資料",
    title2:"登錄設置",
    title3:"修改密碼",
    title4:"綁定谷歌",
    title5:"實名信息",
    tips1:"請使用 Authenticator 掃一掃完成綁定",
    tips2:"密碼只能包含大小字母和數字",
    inputPlaceholder1:"請輸入新密碼",
    inputPlaceholder2:"請輸入舊密碼",
    lable1:"姓名",
    lable2:"身份證",
    lable3:"護照姓名",
    lable4:"護照號碼",
    sumitbtn:"提交",
    switchLabel:"郵箱登錄",
    personalCenter: "個人中心",
    username: "用戶名",
    name: "姓名",
    numberOfInvitedRegistrants: "邀請註冊人數",
    timeOfRegistration: "註冊時間",
    usdBalance: "USD余額",
    whetherOrNotYouHaveARealName: "是否實名",
    numberOfCardsOpened: "開卡數量",
    loginAddress: "登錄地址",
    email: "綁定郵箱",
    cnyBalance: "CNY余額",
    invitationCode: "邀請碼",
    rejectionRate: "拒付率",
    freeze: "凍結",
    realNameNamed: "已實名",
    noRealName: "未實名",
  },
  googleBinding: {
    pre: "請使用",
    content: "Authenticator",
    after: "{掃一掃}功能，掃描二維碼完成谷歌驗證碼登錄綁定",
  },
  changePassword: {
    title: "修改密碼",
    oldPWD: "請輸入舊密碼",
    newPWD: "請輸入新密碼",
    rule: "密碼只能包含大小字母和數字,{a-z,A-z,0-9}",
    confirm: "確 定",
    cancel: "取 消",
  },
  userAuth: {
    title: "实名认证",
    t1: "请进一步完善信息,让LIMAO更了解您!",
    t2: "支付宝",
    t3: "请使用支付宝扫一扫完成身份认证",
    t4: "审核中,请耐心等待!",
    form1: "大陆",
    form2: "海外",
    form3: "类型",
    form4: "姓名",
    form5: "身份证",
    form6: "国家",
    form7: "护照姓名",
    form8: "护照号码",
    form9: "护照",
    place1:"请输入姓名",
    place2:"请输入身份证",
    place3:"请选择国家",
    place4:"请输入护照姓名",
    place5:"请输入护照号码",
    comfirm: "开始实名认证",
    reset: "重新实名认证",
    tip1: "请输入护照信息",
    tip2: "请上传护照图片",
    tip3: "请输入真实姓名",
    tip4: "请输入正确格式身份证号",
    tip5: "护照名字必须全部大写",
    tip6: "护照号码必须全部大写和数字",
    tip7:'将文件拖到此处，或<em>点击上传</em>',
    tip8:"只能上传jpg/png文件",
    tip9:"示例",
    tip10:"请将身份证有头像的一面放入取景框，并保持水平拍摄"
  },
  changeEmail: {
    title: "綁定郵箱",
    emailplaceholder: "請輸入郵箱地址",
    confirm: "確 定",
    cancel: "取 消",
    btnText: "發送郵箱",
    codeplaceholder: "請輸入郵箱驗證碼",
  },
  welcome: {
    customerServiceWeChat: "客服微信",
    mobile: "移動端",
    announcement: "公告",
    home: "首頁",
    main: "主頁",
    working: "工作時間 10:00-18:30",
  },
  onlineRecharge: {
    title: "在線充值",
    home: "首頁",
    depositApplication: "充值申請",
    depositInUsd: "充值面板",
    depositFee: "充值匯率",
    depositFeeTips: "當前匯率 - 銀行賣出 = 手续匯率",
    zfbdepositFeedes: "手續費中包括1%支付寶手續費",
    wxdepositFeedes: "手續費中包括1%微信手續費",
    topUpAmount: "充值金額",
    depositMethod: "充值方式",
    crossBorderTopUp: "跨境充值",
    timelyArrival: "及時到賬",
    hongKongForBusiness: "香港對公",
    ustdRecharge: "泰達充值",
    nextDayArrival: "次日到賬",
    depositCurrency: "充值幣種:",
    usd: "美金",
    theActualAmountReceived: "到賬金額:",
    notAtThisTime: "暫不支持",
    bankTransfer: "銀行轉賬",
    assetOverview: "資產總覽",
    rechargeRecord: "充值記錄",
    assetBalances: "資產余額",
    availableBalance: "可用余額",
    availableBalancedes: "可用余額=資產余額-審核中充值金額",
    freezeTheAmount: "凍結金額",
    frequentlyAskedQuestions: "常見問題",
    des1: "交易後仍然顯示交易中或待支付怎麽辦？",
    des2: "交易成功後，需要幾秒鐘後才能到賬，等待幾秒鐘後仍未更新，可先刷新頁面；等待已久仍然未到賬，可聯系客服或運營經理。",
    weChatPay: "微信支付",
    alipayPayment: "支付寶支付",
    des3: "請於2小時內完成支付",
    close: "關閉",
    submitYourOrder: "提交訂單",
  },
  wallet: {
    home: "首頁",
    depositApplication: "充值申請",
    renminbi: "人民幣",
    title: "錢包",
    availableBalance: "可用余額",
    balanceConversion: "余額轉換",
    des1: "匯率實時更新詳情請根據中國銀行匯率賣出價格進行參考。",
    transferOutOfTheWallet: "轉出錢包",
    des2: "請選擇活動區域",
    usd: "美金",
    theAmountToBeTransferredOut: "轉出金額",
    transferToYourWallet: "轉入錢包",
    transferInAmount: "轉入金額",
    confirmTheRedemption: "確認兌換",
    purse: "錢包",
  },
  transactionHistory: {
    home: "首頁",
    title: "充值記錄",
    depositApplication: "充值申請",
    des4: "開始日期",
    des5: "結束日期",
    des6: "搜索",
    des7: "操作事件",
    des8: "狀態",
    search: "搜索",
    flushed: "刷新",
    download: "Excel",
    redemptionHistory: "兌換記錄",
    orderNumber: "信息",
    remark: "備註",
    topUpUsers: "用戶名",
    creationTime: "創建時間",
    rechargeChannels: "事件",
    alipay: "支付寶",
    wechat: "微信",
    bankCards: "銀行卡",
    manualTopUp: "人工充值",
    manualDeductions: "人工扣除",
    offshoreTopUp: "離岸充值",
    cryptocurrencies: "加密貨幣",
    amount: "金額",
    theEstimatedAmountToBeReceived: "金額",
    theTypeOfDepositCurrency: "幣種",
    walletBalance: "錢包余額",
    topUpStatus: "狀態",
    waitForPayment: "等待付款",
    success: "成功",
    fail: "失敗",
    successpay: "充值成功",
    failpay: "充值失敗",
    cancelThePayment: "取消支付",
    theTopUpExpires: "充值過期",
    operation: "操作",
    repay: "重新支付",
    cancelThePayment: "取消支付",
    weChatPay: "微信支付",
    des1: "請於2小時內完成支付.",
    close: "關閉",
    username: "用戶名",
    orderId: "訂單id",
    redemptionTime: "兌換時間",
    spendingAmountCny: "消費金額(CNY)",
    exchangeRate: "兌換匯率",
    exchangeAmountUsd: "兌換金額(USD)",
    redemptionStatus: "兌換狀態",
    theLastWeek: "近一周",
    theLastMonth: "近一月",
    lastThreeMonths: "近三月",
    CardRecharge: "卡充值",
    CardTransferOut: "卡轉出",
    CardOpeningFee: "開卡費",
    CardSalesTransfer: "銷卡轉入",
    GatewayFailure: "網關失敗",
    CrossFees: "跨境手續費",
    transactionFees: "交易手續費",
    AdvertisingFee: "廣告費支付",
    RefundFees: "廣告費退款",
  },
  balanceDetails: {
    home: "首頁",
    title: "美金記錄",
    depositApplication: "充值申請",
    des1: "請輸入卡號",
    startDate: "開始日期",
    endDate: "結束日期",
    des2: "請選擇事件類型",
    des3: "請選擇支付狀態",
    search: "搜索",
    export: "導出",
    flushed: "刷新",
    cardNumber: "卡號",
    theUserToWhomItBelongs: "所屬用戶",
    operators: "操作人員",
    operatingTime: "操作時間",
    operationalEvents: "操作事件",
    cardTopUp: "卡充值",
    theCardIsTransferredOut: "卡轉出",
    signUpFee: "開卡費",
    cancellationCardTransfer: "銷卡轉入",
    revokeTheTransferIn: "撤銷轉入",
    insufficientBalanceIsDeducted: "網關失敗",
    crossBorderFees: "跨境手續費",
    retroactiveHandlingFee: "補繳手續費",
    refundProcessingFee: "退款手續費",
    cardTransactionFees: "交易手續費",
    advertisingFeePayment: "廣告費支付",
    advertisingFeeRefund: "廣告費退款",
    paymentStatus: "支付狀態",
    success: "成功",
    fail: "失敗",
    amount: "金額",
    walletBalance: "錢包余額(USD)",
    usdRecords: "美金記錄",
    theLastWeek: "近一周",
    theLastMonth: "近一月",
    lastThreeMonths: "近三月",
  },
  applyCard: {
    home: "首頁",
    title: "申請卡片",
    cardManagement: "卡片管理",
    des1: "請輸入卡號段",
    cardStatus: "卡狀態",
    cardNumberSegment: "卡號段",
    cardDecks: "卡組",
    region: "所屬地區",
    cardCurrency: "卡幣種",
    customizeYourBillingAddress: "自定義賬單地址",
    signUpFunction: "開卡功能",
    rechargeFunction: "充值功能",
    rolloutFunction: "轉出功能",
    cancelTheCardFunction: "銷卡功能",
    freezeFunction: "凍結功能",
    numberOfCardsOpened: "開卡數量",
    signUpFee: "開卡費",
    des2: "請聯系客戶經理調整",
    des3: "充值手續費(%)",
    cardDescription: "卡描述",
    operation: "操作",
    yes: "可申請",
    notAvailable: "不可申請",
    des4: "聯系客戶經理開通權限",
    apply: "申請",
    applyForACard: "申請卡片",
    cardInfo:'卡信息',
    cardapplyNumber:'申請數量',
    FcardNumber: "卡編號:",
    FcardNumberSegment: "卡號段:",
    FexpirationDate: "有效期:",
    FcardCurrency: "卡幣種:",
    FcardDescription: "卡描述:",
    FsignUpFee: "開卡費:",
    FdepositFee: "充值手續費:",
    FbalanceUsd: "余額(USD):",
    FbalanceCny: "余額(CNY):",
    FnumberOfApplications: "申請數量:",
    FtopUpAmount: "充值金額",
    FtotalSpending: "合計支出",
    Fcancel: "取消",
    Fconfirm: "確定",
    Fdes5: "手續費內扣卡段建議多充值1-2美金,防止付款失敗.",
  },
  queryCard: {
    home: "首頁",
    title: "查詢卡片",
    cardManagement: "卡片管理",
    des1: "搜索",
    creationTime: "創建時間",
    cardStatus: "卡片狀態",
    search: "搜索",
    flushed: "刷新",
    bulkRecharge: "批量充值",
    downloadTheCard: "下載卡片",
    normalCard: "正常卡",
    numberOfDownloads: "下載次數",
    operation: "操作",
    cardInformation: "卡片信息",
    cardNumber: "卡號",
    currency: "幣種",
    creationTime: "創建時間",
    cardStatus: "卡狀態",
    normal: "正常",
    theCardIsLocked: "已鎖卡",
    cancelledCard: "已銷卡",
    cardNotes: "卡備註",
    des2: "請輸入卡備註",
    cardBalance: "卡片余額",
    synchronous: "同步",
    operation: "操作",
    recharge: "充值",
    cancellationOfTheCard: "銷卡",
    rollOut: "轉出",
    cardDetails: "卡片詳情",
    showCardDetails: "復製,顯示卡詳情",
    month: "月",
    year: "年",
    consumptionHistory: "消費記錄",
    flushed: "刷新",
    refundRecordInquiry: "退款記錄查詢",
    updateTheAddress: "更新地址",
    cardNumber: "卡號",
    theNameOfTheBusiness: "商戶名稱",
    orderTime: "訂單時間",
    theAmountSpent: "消費金額",
    theTypeOfTransaction: "交易類型",
    transactionStatus: "交易狀態",
    transactionDescription: "交易描述",
    theCurrencyOfTheTransaction: "交易幣種",
    updateYourBillingAddress: "更新賬單地址",
    countryCode: "國家碼",
    provinceState: "省/州",
    city: "所在城市(市)",
    zip: "郵編",
    fullAddress: "詳細地址",
    cancel: "取消",
    confirm: "確定",
    cardTopUp: "卡片充值",
    rechargeCardNumber: "充值卡號",
    cardBalance: "卡片余額",
    cardNumberCurrency: "卡號幣種",
    walletBalanceUsd: "錢包余額(USD)",
    cardRechargeFee: "卡充值手續費%",
    topUpAmount: "充值金額",
    estimatedCost: "預計花費",
    rechargeRemarks: "充值備註",
    des3: "手續費內扣卡段建議多充值1-2美金,防止付款失敗.",
    theCardNumberIsTransferredOut: "卡號轉出",
    transferOutCardNumber: "轉出卡號",
    cardNumberBalance: "卡號余額",
    theAmountToBeTransferredOut: "轉出金額",
    rollOutTheNote: "轉出備註",
    refundHistory: "退款記錄",
    cardNumber: "卡號",
    billingCurrency: "賬單幣種",
    transactionStatus: "交易狀態",
    theRefundAuthorizationWasSuccessful: "退款授權成功",
    refundAuthorizationFailed: "退款授權失敗",
    refundAuthorization: "退款授權中",
    punchTheRightSuccess: "沖正成功",
    failedToStraighten: "沖正失敗",
    billAmount: "賬單金額",
    liquidationTime: "清算時間",
    theNameOfTheBusiness: "商戶名稱",
    remark: "備註",
    bulkRecharge: "批量充值",
    walletBalanceUsd: "錢包余額(USD)",
    numberOfTopUpCards: "充值卡數量",
    cardRechargeFee: "卡充值手續費%",
    topUpAmount: "充值金額",
    estimatedCost: "預計花費",
    rechargeRemarks: "充值備註",
    tsdes1: "卡余額不足",
    tsdes2: "安全碼錯誤",
    tsdes3: "卡日期年月錯誤",
    tsdes4: "未知錯誤",
    tsdes5: "卡已失效",
    tsdes6: "持卡人不準交易",
    tsdes7: "風控攔截",
    tsdes8: "卡已失效",
    tsdes9: "安全碼錯誤",
    tsdes10: "MCC錯誤",
    tsdes11: "安全碼錯誤",
    tsdes12: "未激活",
    tsdes13: "涉嫌欺詐風險",
    tsdes14: "持卡人不準交易",
    tsdes15: "拒絕的國家",
    tabsAllCard: "所有卡片",
    addGroup: "添加到分組",
    moveGroup: "移出分組",
    delGroup: "刪除分組",
    addGroup: "新增分組",
    createGroup: "分配分組",
    addCard: "添加卡片",
    tabsTips1: "此操作刪除該分組, 是否繼續?",
    tabsTips2: "提示",
    tabsTips3: "已取消刪除",
    tabsTips4: "請選擇卡片",
    tabsTips5: "請選擇分組",
    tabsTips6: "請選擇卡片",
  },
  saleType: {
    consume: "消費",
    correctionOfAuthorization: "糾正授權",
    verify: "驗證",
    quash: "撤銷",
    refund: "退款",
    validRefunds: "效正退款",
    recharge: "充值",
    cardBalanceRefund: "卡余額退款",
    theCancelledCardIsReturned: "銷卡退回",
    serviceCharge: "服務費",
    revocationOfRefunds: "退款撤銷",
    authorizedConsumption: "授權消費",
    refund: "退款",
    theCardIsTransferredOut: "卡轉出",
    cardTransferIn: "卡轉入",
  },
  saleStatus: {
    theTransactionWasSuccessful: "交易成功",
    theTransactionFailed: "交易失敗",
    trading: "交易中",
    theTransactionReversalIsSuccessful: "交易撤銷成功",
    transactionReversalFailed: "交易撤銷失敗",
  },
  businessHistory: {
    home: "首頁",
    title: "交易記錄",
    cardManagement: "卡片管理",
    des1: "請輸入卡號",
    des2: "請選擇操作事件",
    des3: "請選擇交易狀態",
    startDate: "開始日期",
    endDate: "結束日期",
    search: "搜索",
    flushed: "刷新",
    download: "Excel",
    username: "用戶名",
    operationalEvents: "操作事件",
    cardNumber: "卡號",
    theNameOfTheMerchant: "商戶名稱",
    tradingHours: "交易時間",
    theAmountOfTheTransaction: "交易金額",
    theCurrencyOfTheTransaction: "交易幣種",
    transactionStatus: "交易狀態",
    transactionDescription: "交易描述",
    theLastWeek: "近一周",
    theLastMonth: "近一月",
    lastThreeMonths: "近三月",
  },
  chargebackHistory: {
    home: "首頁",
    title: "拒付記錄",
    cardManagement: "卡片管理",
    rejectionRate: "拒付率",
    failedAmount: "失敗金額",
    clampHeaders: "卡頭",
    theTotalNumberOfTransactions: "總交易數",
    numberOfFailures: "退款筆數",
    theNumberOfChargebacks: "交易失敗筆數",
    theTotalAmountSpent: "總消費金額",
    failedAmount: "失敗金額",
    theAmountOfTheChargeback: "退款金額",
    updated: "更新時間",
  },
  affiliatePromotion: {
    home: "首頁",
    title: "客戶管理",
    des1: "請輸入用戶名",
    username: "用戶名",
    timeOfRegistration: "註冊時間",
    invitationCode: "邀請碼",
    userStatus: "用戶狀態",
    normal: "正常",
    deactivated: "停用",
    toBeReviewed: "待審核",
    notPassed: "不通過",
    operation: "操作",
    rechargeHistory: "充值記錄",
    inquireAboutTheCard: "查詢卡",
    turnoverRecords: "流水記錄",
    des2: "請輸入卡號",
    rechargeChannels: "充值渠道",
    startDate: "開始日期",
    endDate: "結束日期",
    search: "搜索",
    brushNew: "刷 新",
    orderNumber: "訂單編號",
    theUserToWhomItBelongs: "所屬用戶",
    operators: "操作人員",
    operatingTime: "操作時間",
    rechargeChannels: "充值渠道",
    alipay: "支付寶",
    wechat: "微信",
    bankCards: "銀行卡",
    manualTopUp: "人工充值",
    manualDeductions: "人工扣除",
    offshoreTopUp: "離岸充值",
    cryptocurrencies: "加密貨幣",
    theTypeOfDepositAmount: "充值金額類型",
    theAmountReceived: "到賬金額",
    topUpStatus: "充值狀態",
    waitForPayment: "等待付款",
    success: "成功",
    fail: "失敗",
    cancelThePayment: "取消支付",
    queryCards: "查詢卡片",
    des3: "請輸入卡號",
    dateOfApplication: "申請時間",
    cardStatus: "卡片狀態",
    cardTransactionHistory: "卡交易記錄",
    cardNumber: "卡號",
    theNameOfTheBusiness: "商戶名稱",
    orderTime: "訂單時間",
    theAmountSpent: "消費金額",
    theTypeOfTransaction: "交易類型",
    transactionStatus: "交易狀態",
    transactionDescription: "交易描述",
    theCurrencyOfTheTransaction: "交易幣種",
    cardType: "卡類型",
    shareCards: "共享卡",
    cardNumber: "卡號",
    currency: "幣種",
    signUpDate: "開卡日期",
    cardStatus: "卡狀態",
    normal: "正常",
    locked: "鎖卡",
    cancellationOfTheCard: "銷卡",
    availableBalance: "可用余額",
    cardNotes: "卡備註",
    productBtn: "產品配置",
    priceBtn: "價格配置",
    cardConfig: "卡段配置",
    plantag: "機票",
    addtag: "廣告",
    shoptag: "電商",
    apptag: "訂閱",
    othertag: "其他",
    comfirmBtn: "確定",
    priceConfig: "價格配置",
    production: "產品",
    isUserSetConfig: "是否以用戶設置為準",
    cardFee: "開卡費",
    rechargeFee: "充值手續費",
    changeBtn: "修改",
    tips: "權限不足！",
    rejectRecorder: "拒付記錄",
    cardTopUp: "轉出",
    operation: "操作",
    managerPannel: "管理面板",
    cardN: "卡頭",
    Refusal: "拒付率",
    totalSale: "總交易筆數",
    totalFail: "失敗筆數",
    totalRefuse: "拒付筆數",
    totalMoney: "總消費金額",
    refuseMoney: "拒付金額",
    failMoney: "失敗金額",
    upTime: "更新時間",
    cardTransfer: "卡轉出",
    cardNum: "卡號",
    cardbalance: "卡余額",
    transferAmount: "轉出金額",
    remark: "備註",
    cardCanel: "取消",
  },
  partitioninglogs: {
    home: "首頁",
    title: "分成日記",
    des1: "充值用戶名",
    des2: "請選擇支付狀態",
    startDate: "開始日期",
    endDate: "結束日期",
    search: "搜索",
    brushNew: "刷新",
    topUpYourUsername: "充值用戶名",
    theAmountReceivedAfterTheTopUp: "充值後到賬金額",
    itIsDividedIntoCurrencyTypes: "分成貨幣類型",
    theTypeOfTransaction: "交易類型",
    agentsShared: "代理分成",
    userWithdrawalDeduction: "用戶提現扣減",
    splitAmount: "分成金額",
    divideTheTotalBalance: "分成總余額",
    splitExchangeRate: "分成匯率",
    divideIntoTime: "分成時間",
  },
  gg: {
    home: "首頁",
    title: "廣告投放高額返點",
    des1: "請選擇任一媒體開通廣告賬戶，開啟出海之旅",
    des11: "全球最大社交平臺，月活躍度28.9億",
    des2: "若您剛接觸海外社交媒體營銷，推薦您選擇Facebook",
    des3: "無論您的客戶是誰，都能找到他們",
    corporateAccountOpening: "企業開戶",
    des4: "全球最受歡迎的短視頻社交平臺之一",
    des5: "覆蓋150多個國家的超10億用戶",
    des6: "適合目標人群為18-24歲且具有互動偏好性的客戶",
    des7: "全球最大的搜索引擎公司，覆蓋全球90%互聯網用戶",
    des8: "熟悉用戶購物喜好，可追溯行為軌跡",
    des9: "通過關鍵詞搜索匹配您的客戶",
    des10: "微信掃碼添加經理開戶。",
    cancel: "取消",
  },
  enterpriseCertification: {
    home: "首頁",
    title: "企業認證",
    applyForCertification: "申請認證",
    certificationAudits: "認證審核",
    auditResults: "審核結果",
    typeOfBusiness: "企業類型",
    des1: "請選擇企業類型",
    hongKongEnterprises: "香港企業",
    chineseEnterprises: "中國企業",
    theNameOfTheBusiness: "企業名",
    certificateOfIncorporation: "企業註冊證書",
    des2: "僅限一張照片: PNG,JPG,JPEG: 最大10MB",
    reviewStatus: "審核狀態",
    passed: "已通過",
    reject: "駁回",
    application: "申請",
    recertification: "重新認證",
    des3: "認證審核中，我們可能需要最快1-3天內審核完您的資料.請耐心等待......",
    des4: "請選擇企業名稱",
    hongKongEnterprises: "香港企業",
    chineseEnterprises: "中國企業",
    uSBusinesses: "美國企業",
    btn: "點擊上傳",
    tips: "只能上傳PDF文件(要求上傳登記證書、註冊證書、法團成立表格)",
  },
  eCommerceCustomerAcquisition: {
    home: "首頁",
    title: "電商客戶",
    des1: "請輸入廣告口令-識別號",
    search: "搜索",
    brushNew: "刷新",
    applyForAnAdvertisement: "申請廣告",
    reviewStatus: "審核狀態",
    approved: "審核通過",
    underReview: "審核中",
    reviewRejection: "審核駁回",
    taskStatus: "任務狀態",
    didnTStart: "未開始",
    screenshotOfTheTask: "任務截圖",
    pause: "暫停",
    comeToAnEnd: "結束",
    passwordIdentificationNumber: "口令-識別號",
    deliveryMode: "投放模式",
    flowRate: "流量",
    sales: "銷量",
    theTypeOfImpression: "展示類型",
    site: "網站",
    informationApplications: "信息應用",
    targetStrategy: "目標策略",
    maximizeConversions: "轉化量最大化",
    maximizeConversionValue: "轉化價值最大化",
    startDate: "開始日期",
    startTime: "開始時間",
    endDate: "結束日期",
    endTime: "結束時間",
    deliveryBudget: "投放預算",
    numberOfDaysForDuration: "持續天數",
    totalBudget: "總預算",
    totalCost: "總花費",
    country: "投放國家",
    launchCity: "投放城市",
    ageOfTheTargetGroup: "目標人群年齡",
    genderOfTheTargetGroup: "目標人群性別",
    language: "語言",
    adHeadline: "廣告標題",
    descriptionOfTheAd: "廣告描述",
    advertisingImages: "廣告圖片",
    theUrlOfTheAdvertisingWebsite: "廣告網站URL",
    whatsAppContacts: "WhatsApp聯系人",
    creationTime: "創建時間",
    updated: "更新時間",
    operation: "操作",
    des2: "審核駁回才允許編輯",
    editorial: "編輯",
    des3: "將您的品牌",
    des4: "推向更廣闊的市場！",
    des5: "投放模式",
    des6: "吸引更多的用戶流量到您的網站或應用，增加品牌知名度和曝光度。",
    des7: "促進產品銷售，提高轉化率和訂單量，以實現更直接的商業目標。",
    theTypeOfImpression: "展示類型",
    websiteDelivery: "網站投放",
    appInformation: "應用信息",
    targetStrategy: "目標策略",
    pleaseSelectATargetPolicy: "請選擇目標策略",
    des8: "廣告帶來的轉化次數最多，例如點擊購買、提交表單或其他你定義的轉化行動。這意味著更多的用戶在廣告的引導下執行了預期的行動，提高了廣告的效果。",
    maximizeConversions: "轉化量最大化",
    des9: "不同於單純的轉化次數，轉換價值考慮了每次轉化的質量和價值。例如，用戶購買高價商品所帶來的價值高於購買低價商品。這種情況下，廣告優化的重點是提高每次轉化的質量和價值。",
    maximizeConversionValue: "轉化價值最大化",
    budgetForAdServing: "廣告投放預算",
    advertisingBudget: "廣告預算",
    premium: "手續費",
    totalBudget: "總預算",
    balance: "余額",
    theNumberOfDaysTheAdHasBeenRunning: "廣告投放天數",
    des10: "請輸入投放天數",
    startTime: "開始時間",
    des11: "請選擇開始時間",
    des12: "選擇開始時間",
    endTime: "結束時間",
    endDate: "結束日期",
    region: "投放地區",
    des13: "請使用英文輸入國家",
    des14: "請輸入投放國家",
    des15: "請使用英文輸入城市",
    des16: "請輸入投放城市",
    genderOfTheTargetGroup: "目標群體性別",
    des17: "請選擇目標群體",
    random: "隨機",
    man: "男人",
    woman: "女人",
    des18: "目標群體年齡",
    des19: "目標群體年齡18-35歲",
    deliveryLanguage: "投放語言",
    des20: "請輸入語言",
    adHeadline: "廣告標題",
    des21: "請輸入廣告標題",
    descriptionOfTheAd: "廣告描述",
    des22: "請輸入廣告描述",
    serveYourWebsite: "投放網站",
    des23: "請輸入投放網站",
    contact: "聯系方式",
    des24: "請輸入您的whatsapp聯系方式",
    previous: "上一步",
    next: "下一步",
    apply: "申請",
    cancel: "取消",
    eCommerceGoesOverseas: "海外電商",
  },
  balanceWithdrawals: {
    home: "首頁",
    title: "余額提現",
    walletBalance: "錢包余額",
    payouts: "提現",
    withdrawalsAreFrozen: "資金凍結",
    withdrawalExchangeRate: "提現匯率",
    orderNumber: "訂單號",
    withdrawalUsername: "提現用戶名",
    theAmountToBeWithdrawn: "提現金額",
    withdrawalCurrency: "提現幣種",
    payoutStatus: "提現狀態",
    underReview: "審核中",
    approved: "審核通過",
    reviewRejection: "審核駁回",
    alipayAccount: "支付寶賬號",
    alipayName: "支付寶姓名",
    withdrawalRequestRemarks: "提現申請備註",
    approvalNotes: "審核備註",
    underReview: "審核中",
    reviewTime: "審核時間",
    withdrawalTime: "提現時間",
    payoutStatus: "提現狀態",
    theCurrencyOfTheTransaction: "交易幣種",
    search: "搜索",
    flushed: "刷新",
    withdrawalRequests: "提現申請",
    orderNumber: "訂單號",
    withdrawalUsername: "提現用戶名",
    theAmountToBeWithdrawn: "提現金額",
    withdrawalCurrency: "提現幣種",
    payoutStatus: "提現狀態",
    underReview: "審核中",
    approved: "審核通過",
    reviewRejection: "審核駁回",
    alipayAccount: "支付寶賬號",
    alipayName: "支付寶姓名",
    withdrawalRequestRemarks: "提現申請備註",
    approvalNotes: "審核備註",
    reviewTime: "審核時間",
    withdrawalTime: "提現時間",
    withdrawalRequests: "提現申請",
    dollar: "美元",
    balance: "余額",
    withdrawalRemake: "提現備註",
    theAmountReceived: "到賬金額",
    submitAnApplication: "提交申請",
    theLastWeek: "近一周",
    theLastMonth: "近一月",
    lastThreeMonths: "近三月",
    tips1: "不合適場景使用",
    tips2: "暫時不需要使用",
    tips3: "交易問題未解決",
    tips4: "其他原因",
  },
  login: {
    logInToYourInstantAccount: "登錄您的即時賬戶",
    signUpForAnAccount: "沒有賬戶?註冊賬號",
    ForgotPassword: "忘記密碼",
    des1: "請輸入用戶名",
    des2: "請輸入密碼",
    des3: "請輸入驗證碼",
    des4: "請輸入谷歌驗證碼",
    login: "登錄",
    otherWays: "其他方式",
    googleLogin: "谷歌登錄",
    accountLogin: "賬號登錄",
    coderule: "驗證碼不能為空",
    userrule: "用戶名不能為空",
    pwdrule: "密碼不能為空",
    lengthrule: "長度在 5 到 12 個字符",
    abcrule: "密碼只能包含大小字母和數字,{a-z,A-z,0-9}",
    ggrule: "驗證碼長度為6",
  },
  forgot: {
    title: "找回密碼",
    backLogin: "返回登錄",
    signUpForAnAccount: "沒有賬戶?註冊賬號",
    des1: "請輸入用戶名",
    des2: "請輸入郵箱",
    des3: "請輸入驗證碼",
    des4: "請輸入新密碼",
    des5: "請再次輸入新密碼",
    submit: "修改密碼",
    coderule: "驗證碼不能為空",
    userrule: "用戶名不能為空",
    pwdrule: "密碼不能為空",
    lengthrule: "長度在 5 到 12 個字符",
    abcrule: "密碼只能包含大小字母和數字,{a-z,A-z,0-9}",
    emailrule: "請輸入郵箱地址",
    sendBtn: "發送郵箱",
  },
  register: {
    signUpForYourInstantAccount: "註冊您的即時賬戶",
    country: "國家",
    des1: "請輸入手機號碼",
    des2: "請輸入密碼",
    des3: "請再次輸入密碼",
    des4: "請輸入邀請碼",
    des5: "請輸入姓名",
    des6: "請輸入身份證號碼",
    des7: "請輸入驗證碼",
    des8: "兩次密碼輸入不一致",
    des9: "請輸入郵箱",
    enroll: "註冊",
    readAndAgree: "閱讀並同意",
    registrationAgreement: "註冊協議",
    privacyAgreement: "隱私協議",
    refundAgreement: "保密協議",
    logInNow: "已有賬戶?立即登錄",
    userrule: "用戶名不能為空",
    userLengthrule: "長度在 11 個字符",
    pswrule: "密碼不能為空",
    pswLengthrule: "長度在 5 到 15 個字符",
    pswabcrule: "密碼只能包含大小字母和數字,{a-z,A-z,0-9}",
    pswAgainrule: "兩次密碼輸入不一致",
    codeurule: "邀請碼必須填寫",
    namerule: "姓名必須填寫",
    chineserule: "必須填寫中文",
    idrule: "身份證號必須填寫",
  },
  globalpage: {
    footer: " ",
  },
  ...twLocale,
};
// 导出
export default hk;
