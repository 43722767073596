<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('balanceDetails.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('balanceDetails.depositApplication') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('balanceDetails.title') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <!-- 搜索框 -->
          <div class="user_box">
            <el-form :model="form_balance" :rules="form_balanceRules" ref="form_balanceFormRef"
              :label-position="labelPosition">
              <!-- 输入卡号 -->
              <el-input v-model="form_balance.cardNo" prefix-icon="el-icon-tickets"
                :placeholder="$t('balanceDetails.des1')">
              </el-input>
              <!-- 日期选择 -->
              <el-date-picker v-model="dataTimes" type="daterange" align="right" unlink-panels range-separator="-"
                :start-placeholder="$t('balanceDetails.startDate')" :end-placeholder="$t('balanceDetails.endDate')"
                :picker-options="pickerOptions2" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker>
              <!-- 事件类型 -->
              <el-select v-model="selectVlues" :placeholder="$t('balanceDetails.des2')" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 支付状态 -->
              <el-select v-model="selelctRechargeStatusVal" :placeholder="$t('balanceDetails.des3')" clearable>
                <el-option v-for="item in selelctRechargeStatus" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-button icon="el-icon-search" @click="searchUser()">{{ $t('balanceDetails.search')
                }}</el-button>
              <el-button icon="el-icon-upload2" @click="exportEx()">{{ $t('balanceDetails.export')
                }}</el-button>
              <el-button icon="el-icon-refresh" @click="searchUser('1')">{{ $t('balanceDetails.flushed')
                }}</el-button>
            </el-form>
          </div>
          <!-- 表格 + 分页 -->
          <div class="user_box">
            <el-table :data="tableData" style="width: 100%" border height="660" max-height="700" size="small"
              highlight-current-row>
              <el-table-column :label="$t('balanceDetails.cardNumber')" prop="mycard_id"> </el-table-column>
              <el-table-column :label="$t('balanceDetails.theUserToWhomItBelongs')" prop="users"> </el-table-column>
              <el-table-column :label="$t('balanceDetails.operators')" prop="actionusers">
              </el-table-column>
              <el-table-column :label="$t('balanceDetails.operatingTime')" prop="addtime">
              </el-table-column>
              <el-table-column :label="$t('balanceDetails.operationalEvents')">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.source === 1"> {{ $t('balanceDetails.cardTopUp') }} </el-tag>
                  <el-tag v-else-if="scope.row.source === 2"> {{ $t('balanceDetails.theCardIsTransferredOut') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.source === 3"> {{ $t('balanceDetails.signUpFee') }} </el-tag>
                  <el-tag v-else-if="scope.row.source === 4"> {{ $t('balanceDetails.cancellationCardTransfer') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.source === 5"> {{ $t('balanceDetails.revokeTheTransferIn') }} </el-tag>
                  <el-tag v-else-if="scope.row.source === 6">
                    {{ $t('balanceDetails.insufficientBalanceIsDeducted') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.source === 7">
                    {{ $t('balanceDetails.crossBorderFees') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.source === 8">
                    {{ $t('balanceDetails.retroactiveHandlingFee') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.source === 9">
                    {{ $t('balanceDetails.refundProcessingFee') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.source === 10">
                    {{ $t('balanceDetails.cardTransactionFees') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.source === 11">
                    {{ $t('balanceDetails.advertisingFeePayment') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.source === 12">
                    {{ $t('balanceDetails.advertisingFeeRefund') }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- 支付状态 -->
              <el-table-column prop="status" :label="$t('balanceDetails.paymentStatus')" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 1" type="success">
                    {{ $t('balanceDetails.success') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 2" type="danger">
                    {{ $t('balanceDetails.fail') }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column :label="$t('balanceDetails.amount')" prop="changem"> </el-table-column>
              <el-table-column :label="$t('balanceDetails.walletBalance')" prop="aftermon">
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      name: "美金记录",
      total: 0,
      labelPosition: "right",
      selectVlues: "",
      selelctRechargeStatusVal: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      dataTimes: [],
      form_balance: {
        cardNo: "",
      },
      form_balanceRules: {},
      // 支付状态
      selelctRechargeStatus: [
        {
          value: "1",
          label: this.$t('balanceDetails.success'),
        },
        {
          value: "2",
          label: this.$t('balanceDetails.fail'),
        },
      ],
      // 选择事件类型
      options: [
        {
          value: "1",
          label: this.$t('balanceDetails.cardTopUp'),
        },
        {
          value: "2",
          label: this.$t('balanceDetails.theCardIsTransferredOut'),
        },
        {
          value: "3",
          label: this.$t('balanceDetails.signUpFee'),
        },
        {
          value: "4",
          label: this.$t('balanceDetails.cancellationCardTransfer'),
        },
        // {
        //   value: "5",
        //   label: this.$t('balanceDetails.revokeTheTransferIn'),
        // },
        {
          value: "6",
          label: this.$t('balanceDetails.insufficientBalanceIsDeducted'),
        },
        {
          value: "7",
          label: this.$t('balanceDetails.crossBorderFees'),
        },
        // {
        //   value: "8",
        //   label: this.$t('balanceDetails.retroactiveHandlingFee'),
        // },
        // {
        //   value: "9",
        //   label: this.$t('balanceDetails.refundProcessingFee'),
        // },
        {
          value: "10",
          label: this.$t('balanceDetails.cardTransactionFees'),
        },
        {
          value: "11",
          label: this.$t('balanceDetails.advertisingFeePayment'),
        },
        {
          value: "12",
          label: this.$t('balanceDetails.advertisingFeeRefund'),
        },
      ],
      pickerOptions2: {
        shortcuts: [
          {
            text: this.$t('balanceDetails.theLastWeek'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t('balanceDetails.theLastMonth'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t('balanceDetails.lastThreeMonths'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    // 导出数据
    exportEx() {
      if (!this.dataTimes) {
        this.dataTimes = ["", ""]
      }
      let params = {
        card_num: this.form_balance.cardNo,
        event_type: this.selectVlues,
        pay_type: this.selelctRechargeStatusVal,
        start: this.dataTimes[0],
        ends: this.dataTimes[1],
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/exportUsdRecord", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          const contentType = res.headers.get("Content-Type");
          const fileType = contentType.split("/")[1].split(";")[0];
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const a = document.createElement("a");
          a.href = url;
          a.download = `exportedFile.${fileType}`; // 指定文件名
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 搜索
    searchUser(rf) {
      if (rf == "1") {
        this.currentPage = 1;
        this.pageSize = 100;
        this.form_balance.cardNo = "";
        this.selectVlues = "";
        this.selelctRechargeStatusVal = "";
        this.dataTimes = ["", ""]
      }
      if (!this.dataTimes) {
        this.dataTimes = ["", ""]
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        card_num: this.form_balance.cardNo,
        event_type: this.selectVlues,
        pay_type: this.selelctRechargeStatusVal,
        start: this.dataTimes[0],
        ends: this.dataTimes[1],
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getUsdRecord", params, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].addtime = this.getTime(
              res.data.data.data[i].addtime
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.searchUser();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchUser();
    },
    // 充值状态过滤
    filterTag(value, row) {
      return row.payStatus === value;
    },
  },
  created() {
    this.searchUser();
  },
  mounted() { },
};
</script>

<style scoped>
.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.el-range-editor {
  margin-right: 3%;
}

.el-button {
  margin-right: 2%;
}
</style>
