<template>
  <el-main>
    <div class="title_tab">{{ name }}</div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <el-form :model="form_bank" :rules="bankFormRules" ref="form_bankFormRef" :label-position="labelPosition"
              label-width="100px">
              <!-- 银行卡号 -->
              <el-form-item prop="bankNo" label="银行卡号:">
                <el-input v-model="form_bank.bankNo" disabled prefix-icon="el-icon-bank-card"
                  placeholder="请联系管理员获取银行卡号">
                </el-input>
              </el-form-item>
              <!-- 户行名称 -->
              <el-form-item prop="depositName" label="户行名称:">
                <el-input v-model="form_bank.depositName" disabled prefix-icon="el-icon-tickets"
                  placeholder="请联系管理员获取开户行名称:">
                </el-input>
              </el-form-item>
              <!-- 户主名称 -->
              <el-form-item prop="bankUsername" label="户主名称:">
                <el-input v-model="form_bank.bankUsername" disabled prefix-icon="iconfont icon-user"
                  placeholder="请联系管理员获取户主名称"></el-input>
              </el-form-item>
              <!-- 可用余额 -->
              <el-form-item prop="balance" label="可用余额:">
                <el-input v-model="form_bank.balance" disabled prefix-icon="el-icon-coin" placeholder="0.00"></el-input>
              </el-form-item>
              <!-- 充值汇率 -->
              <el-form-item prop="percentage" label="充值手续费(%):">
                <el-input v-model="form_bank.percentage" disabled prefix-icon="el-icon-coin" placeholder="0.00">
                </el-input>
              </el-form-item>
              <!-- 付款姓名 -->
              <el-form-item prop="paymentName" label="付款姓名:">
                <el-input v-model="form_bank.paymentName" prefix-icon="iconfont icon-user" placeholder="请填写付款人姓名">
                </el-input>
              </el-form-item>
              <!-- 付款卡号 -->
              <el-form-item prop="paymentBank" label="付款卡号:">
                <el-input v-model="form_bank.paymentBank" prefix-icon="el-icon-bank-card"
                  placeholder="请输入付款人卡号,如:XXXX XXXX XXXX XXXX"></el-input>
              </el-form-item>
              <!-- 充值金额 -->
              <el-form-item prop="RechargeAmount" label="充值金额:">
                <el-input v-model="form_bank.RechargeAmount" prefix-icon="el-icon-coin" placeholder="0.00"
                  :change="getAmount()"></el-input>
              </el-form-item>
              <!-- 预计到账 -->
              <el-form-item prop="Amount" label="预计到账:">
                <el-input v-model="form_bank.Amount" disabled prefix-icon="el-icon-coin" placeholder="0.00"></el-input>
              </el-form-item>
              <!-- 提交订单 -->
              <el-form-item>
                <el-button v-no-more-click @click="subRechargeBank()">提交订单</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      name: '美金充值',
      labelPosition: 'right',
      // 转账表单
      form_bank: {
        bankNo: '5556546879563215',
        balance: 0,
        percentage: '7.38',
        paymentName: '',
        paymentBank: '',
        RechargeAmount: 0,
        Amount: 0
      },
      // 表单验证
      bankFormRules: {
        paymentName: [{
          required: true,
          message: '付款人姓名不能为空',
          tigger: 'blur'
        }],
        paymentBank: [{
          required: true,
          message: '付款人银行卡号不能为空',
          tigger: 'blur'
        }],
        RechargeAmount: [{
          required: true,
          message: '付款人银行卡号不能为空',
          tigger: 'blur'
        }]
      }
    }
  },
  methods: {
    getInfo() {
      const result = this.$http.get('getInfo', {
        headers: {
          Authorization: window.localStorage.getItem('token')
        }
      })
      result.then((res) => {
        // 余额
        this.form_bank.balance = res.data.user.usableQuota
      }, (error) => {
        console.log('错误:' + error)
      })
    },
    getAmount() {
      this.form_bank.Amount = Math.floor((this.form_bank.RechargeAmount / this.form_bank.percentage) * 100) / 100
    },
    subRechargeBank() {
      if (this.form_bank.RechargeAmount <= 0) {
        return this.$message.error('订单金额必须大于0!')
      }
    }
  },
  created() {
    this.getInfo()
  }
}
</script>

<style scoped>
.el-input {
  width: 80%;
}
</style>
