<template>
  <div class="container">
    <div class="db-RegisterAndLoginBackground-background">
      <div class="db-RegisterAndLoginBackground-grayBackground"></div>
      <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--first"></div>
      <!-- <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--second"></div> -->
      <!-- <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--third"></div> -->
      <!-- <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--fourth"></div> -->
      <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--fifth"></div>
      <div class="db-RegisterAndLoginBackground-firstLeftStripe" style="background: rgb(128, 233, 255)"></div>
      <div class="db-RegisterAndLoginBackground-secondLeftStripe" style="background: rgb(122, 115, 255)"></div>
      <div class="db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend" style="background: rgb(0, 72, 229)">
      </div>
      <div class="db-RegisterAndLoginBackground-firstRightStripe" style="background: rgb(122, 115, 255)"></div>
    </div>
    <!-- 表单 -->
    <div class="db-RegisterAndLoginLayout-contentWrapper Box-root">
      <div class="db-RegisterAndLoginLayout-formContainer Box-root">
        <!-- <div class="db-RegisterAndLoginLayout-logo Box-root">
          <div title="Stripe"
            class="SVGInline SVGInline--cleaned SVG Logo Icon-color Icon-color--gray800 Box-root Flex-flex">
            LIMAO INC
          </div>
        </div> -->
        <!-- form -->
        <div class="db-RegisterAndLoginLayout-form">
          <!-- 登录表单 -->
           <div class="db-RegisterAndLoginLayout-form-row1">找回密码</div>
           <!-- 用户名 -->
            <div class="db-RegisterAndLoginLayout-form-row2">
              <div class="db-RegisterAndLoginLayout-form-row2-label">用户名</div>
              <el-input style="margin-top: 12px;" v-model="form_login.username"></el-input>
            </div>
             <!-- 邮箱 -->
            <div class="db-RegisterAndLoginLayout-form-row2 martop--32">
              <div class="db-RegisterAndLoginLayout-form-row2-label">
                <span>邮箱</span>
              </div>
              <el-input style="margin-top: 12px;" v-model="form_login.email" ></el-input>
            </div>
            <!-- 验证码 -->
            <div class="db-RegisterAndLoginLayout-form-row3 martop--32">
              <div class="db-RegisterAndLoginLayout-form-row3-label">
                <span>验证码</span>
              </div>
              <div class="db-RegisterAndLoginLayout-form-row3-code">
                <el-input style="margin-top: 12px;width: 45%;" v-model="form_login.code"></el-input>
                <!-- 邮箱按钮 -->
                <button class="codebtn" @click="sendEmail" :disabled="sendAble" :loading="sendAble" >
                  <i v-show="sendAble" class="el-icon-loading"></i>
                  {{ countdown }}
                </button>
              </div>
            </div>
            <div class="db-RegisterAndLoginLayout-form-row2 martop--32">
              <div class="db-RegisterAndLoginLayout-form-row2-label">
                <span>密码</span>
              </div>
              <el-input style="margin-top: 12px;" type="password" v-model="form_login.password" ></el-input>
            </div>
            <div class="db-RegisterAndLoginLayout-form-row2 martop--32">
              <div class="db-RegisterAndLoginLayout-form-row2-label">
                <span>确认密码</span>
              </div>
              <el-input style="margin-top: 12px;" type="password" v-model="form_login.rgpassword" ></el-input>
            </div>
            <!-- 按钮 -->
             <div class="db-RegisterAndLoginLayout-form-row4 ">
                <div class="db-RegisterAndLoginLayout-form-row4-btn" @click="changePwd">保存</div>
             </div>
             <!-- 注册 -->  
            <div class="db-RegisterAndLoginLayout-foot">
                <span>已有账户?</span><span @click="go_login()" class="btn_foot">返回登录</span>
            </div>
        </div>
      
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 表单数据绑定对象
      form_login: {
        username: this.$cookie.get('user'),
        password: '',
        code: '',
        email: '',
        rgpassword: ''
      },
      countdown: this.$t('forgot.sendBtn'), // 倒计时
      // 表单验证规则
      loginFormRules: {
        // 验证验证码
        code: [{
          required: true,
          message: this.$t('forgot.coderule'),
          tigger: 'blur'
        }],
        // 验证用户名
        username: [{
          required: true,
          message: this.$t('forgot.userrule'),
          tigger: 'blur'
        },
        {
          min: 5,
          max: 12,
          message: this.$t('forgot.lengthrule'),
          trigger: 'blur'
        }
        ],

        email: [
          { required: true, message: this.$t('forgot.emailrule'), trigger: 'blur' },
        ],
        // 验证密码
        password: [{
          required: true,
          message: this.$t('forgot.pwdrule'),
          tigger: 'blur'
        },
        {
          min: 5,
          max: 15,
          message: this.$t('forgot.lengthrule'),
          trigger: 'blur'
        }, {
          pattern: '^[0-9a-zA-Z_]{1,}$',
          message: this.$t('forgot.abcrule')
        }
        ],
        rgpassword: [{
          required: true,
          message: this.$t('forgot.pwdrule'),
          tigger: 'blur'
        },
        {
          min: 5,
          max: 15,
          message: this.$t('forgot.lengthrule'),
          trigger: 'blur'
        }, {
          pattern: '^[0-9a-zA-Z_]{1,}$',
          message: this.$t('forgot.abcrule')
        }
        ]
      },
      sendAble: false, // 是否可发送邮箱
    }
  },
  methods: {
    // 修改新密码
    changePwd() {
        if(!this.form_login.username){
          this.$message.error("请输入用户名")
          return
        }
        if(!this.form_login.password){
          this.$message.error("请输入新密码")
          return
        }
        if(!this.form_login.email){
          this.$message.error("请输入邮箱")
          return
        }
        if(!this.form_login.rgpassword){
          this.$message.error("请确认密码")
          return
        }
        if(!this.form_login.code){
          this.$message.error("请输入验证码")
          return
        }
        const params = {
          pass: this.form_login.password,
          email: this.form_login.email,
          rpass: this.form_login.rgpassword,
          code: this.form_login.code,
          users: this.form_login.username
        }
        const result = this.$http.post('/api/v1/resetpass', params)
        result.then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg)
            this.go_login()
          } else {
            this.$message.error(res.data.msg)
          }
        }, (error) => {
          this.$message.error('登录错误，请重新登录!')
        })
    },
    // 等待5分钟重新发生邮箱
    waitEmailTime() {
      this.sendAble = true
      let countdownSeconds = 300; // 设置倒计时时间，单位为秒
      this.countdown = countdownSeconds; // 将倒计时时间存储到组件的属性中
      const countdownInterval = setInterval(() => {
        this.countdown--; // 每秒减少一秒
        if (this.countdown <= 0) {
          clearInterval(countdownInterval); // 倒计时结束，清除定时器
          this.sendAble = false;
          this.countdown = this.$t('forgot.sendBtn')
        }
      }, 1000); // 每秒执行一次
    },
    // 发生邮箱
    sendEmail() {
      // this.$refs.form_loginFormRef.validate(async valid => {
      //   if (!valid) return this.$message.error('登录错误，请重新登录!')
      if (!this.form_login.email) return this.$message.error(this.$t('forgot.des2'))
      if (!this.form_login.username) return this.$message.error(this.$t('forgot.des1'))
      const params = {
        users: this.form_login.username,
        email: this.form_login.email
      }
      const result = this.$http.post('/api/v1/sendemail', params)
      result.then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg)
          this.waitEmailTime()
        } else {
          this.$message.error(res.data.msg)
        }
      }, (error) => {
        this.$message.error('发送邮件失败!')
      })
      // })
    },
    // 跳转到注册页面
    go_reg() {
      this.$router.push('/register')
    },
    // 登录页面
    go_login() {
      this.$router.push('/')
    },

  },
  mounted: function () {

  },
  created() {

  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  min-height: 100%;
  height: 100%;
  background-color: #fff;
  flex-direction: column;
  box-sizing: border-box;
}

.db-RegisterAndLoginBackground-background {
  z-index: 0;
  scrollbar-gutter: stable;
  width: 100vw;
  position: fixed;
  top: -250px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: skewY(-12deg);
}

.db-RegisterAndLoginBackground-grayBackground {
  transform-origin: 0%;
  height: 1698px;
  background-color: rgb(247, 250, 252);
  position: absolute;
  top: -1000px;
  left: 0;
  right: 0;
}

.db-RegisterAndLoginBackground-verticalLine--first {
  left: calc(50% - 540px);
}

.db-RegisterAndLoginBackground-verticalLine--fifth,
.db-RegisterAndLoginBackground-verticalLine--first {
  background: #eceef4;
}

.db-RegisterAndLoginBackground-verticalLine {
  width: 1px;
  background-image: linear-gradient(#eceef4 49%, #eceef400 50%);
  background-size: 1px 8px;
  position: absolute;
  top: -100vh;
  bottom: -100vh;
  right: auto;
}

.db-RegisterAndLoginBackground-verticalLine--second {
  left: calc(50% - 270px);
}

.db-RegisterAndLoginBackground-verticalLine--third {
  left: 50%;
}

.db-RegisterAndLoginBackground-verticalLine--fourth {
  left: calc(50% + 269px);
}

.db-RegisterAndLoginBackground-verticalLine--fifth {
  left: calc(50% + 540px);
}

.db-RegisterAndLoginBackground-verticalLine--fifth,
.db-RegisterAndLoginBackground-verticalLine--first {
  background: #eceef4;
}

.db-RegisterAndLoginBackground-firstLeftStripe {
  height: 40px;
  top: 668px;
  left: calc(50% - 700px);
  right: calc(50% + 540px);
}

.db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend,
.db-RegisterAndLoginBackground-firstLeftStripe,
.db-RegisterAndLoginBackground-firstRightStripe,
.db-RegisterAndLoginBackground-secondLeftStripe {
  position: absolute;
}

.db-RegisterAndLoginBackground-secondLeftStripe {
  height: 40px;
  top: 698px;
  left: -10px;
  right: calc(50% + 405px);
}

.db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend {
  height: 10px;
  top: 698px;
  left: calc(50% - 700px);
  right: calc(50% + 540px);
}

.db-RegisterAndLoginBackground-firstRightStripe {
  height: 40px;
  top: 658px;
  left: calc(50% + 430px);
  right: -10px;
}

.Box-root {
  box-sizing: border-box;
}

.db-RegisterAndLoginLayout-logo {
  padding-bottom: 40px;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 900;
}

.Icon-color--gray800 {
  color: rgb(42, 47, 69);
}

.db-RegisterAndLoginLayout-contentWrapper {
  width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.db-RegisterAndLoginLayout-formContainer {
  padding-top: 5%;
  width: 540px;
  min-width: 540px;
  display: flex;
  flex-direction: column;
}

.db-RegisterAndLoginLayout-form {
  position: relative;

  background: #fff;
  border-radius: 8px;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
    0 5px 15px 0 rgba(0, 0, 0, 0.12);
  transform: translate3d(0px, 0px, 0);
  transform-origin: 0% 0%;
  width: 540px;
  min-width: 540px;
  height: 800px;
  will-change: transform, width, height;
  box-sizing: border-box;
  padding: 56px 48px;
}

.db-RegisterAndLoginLayout-form-row1{
  margin-top: 20px;
  margin-left: 20px;
  font-family: sohne-var, sohne-woff, Helvetica Neue, Arial, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -.2px;
  color: rgb(60,66,87);
}

.db-RegisterAndLoginLayout-form-row2{
  margin-top: 20px;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -.2px;
  color: rgb(60,66,87);
}

.db-RegisterAndLoginLayout-form-row3{
  margin-top: 20px;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -.2px;
  color: rgb(60,66,87);
}

.db-RegisterAndLoginLayout-form-row2-label{
  display: flex;
  justify-content: space-between;
}
.db-RegisterAndLoginLayout-form-row3-code{
  display: flex;
}

.codeImg {
  margin-left: 5%;
  margin-top: 12px;
  width: 50%;
  height: 40px;
  border-radius: 5px;
}

.codebtn{
  cursor: pointer;
  margin-left: 5%;
  margin-top: 12px;
  width: 50%;
  height: 40px;
  border-radius: 5px;
  color: #71717a;
  font-weight: 800;
  background-color: #fff;
  border: 1px solid #C0C4CC;
  transition: all .3s ease-in-out;
}

.codebtn:disabled{
  user-select: none;
  cursor: not-allowed;
  color: #fff;
  background-color: #4F4F5F;
}

.codebtn:hover{
  color: #fff;
  background-color: #635BFF;
}

/* 确保禁用状态下的按钮不会应用悬停样式 */
.codebtn:disabled:hover {
  color: #fff;
  background-color: #4F4F5F;
  border-color: #4F4F5F; /* 保持边框颜色一致 */
}

.db-RegisterAndLoginLayout-form-row4{
  margin-top: 20px;
  margin-left: 20px;
}
.db-RegisterAndLoginLayout-form-row4-btn{
  cursor: pointer;
  margin-top: 40px;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-out;
  background-color: rgb(99, 91, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(69, 56, 255, 0.8) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
}

.db-RegisterAndLoginLayout-form-row4-btn:hover{
  transform: translateY(-3px);
}

.db-RegisterAndLoginLayout-form-row4-btn:active{
  transform: translateY(3px);
}

.martop--32{
  margin-top: 32px;
}

.db-RegisterAndLoginLayout-foot{
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 98%;
  height: 50px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: #f6f9fc;
  
}
.btn_foot{
  cursor: pointer;
  font-weight: 600;
  color: #635bff;
  transition: all .1s linear;
}
.btn_foot:hover{
  color: #000;
}

/deep/ .el-button{
  font-size:14px ;
  font-weight: 800;
  border: 1.2px solid #635bff;
  color:#635bff ;
}

/deep/ .el-button:focus, .el-button:hover{
  background: #635bff;
  color: #fff;
}
</style>
